import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paginationPageData: {},
  activeRerender: false,
};

const HelpersSlice = createSlice({
  name: "HelpersSlice",
  initialState,
  reducers: {
    setPaginationPageData: (state, action) => {
      state.paginationPageData = action.payload;
    },
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    },
  },
});

export default HelpersSlice.reducer;
export const { setPaginationPageData, setActiveRerender } =
  HelpersSlice.actions;
