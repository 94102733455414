import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planListing: null,
};

const PlanSlice = createSlice({
  name: "PlanSlice",
  initialState,
  reducers: {
    setPlanListing: (state, action) => {
      state.planListing = action.payload;
    },
  },
});

export default PlanSlice.reducer;
export const { setPlanListing } = PlanSlice.actions;
