import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { useSelector } from "react-redux";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const navLoader = useSelector((state) => state.auth.navLoader);

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar sx={{ minHeight: "45px !important" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item sx={{ md: "none" }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="small"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          {navLoader ? (
            <Grid item>
              <CircularProgress thickness={5} size={28} />
            </Grid>
          ) : null}
          <Grid item>
            <NavbarUserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withTheme(Navbar);
