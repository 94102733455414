import React, { Suspense, memo } from "react";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AuthProvider } from "./contexts/JWTContext";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import NotificationAlerts from "./components/alerts/NotificationAlerts";
import Router from "./routes";
import HashLoaderBackdrop from "./components/HashLoaderBackdrop";

function App() {
  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Chat With PDF"
        defaultTitle="Chat With PDF - Admin Dashboard"
      />
      <NotificationAlerts />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <AuthProvider>
            <Suspense fallback={<HashLoaderBackdrop />}>
              <Router />
            </Suspense>
          </AuthProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default memo(App);
