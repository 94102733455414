import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { localStorageKeys } from "../../config/config";

export const signIn = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON

      const { data } = await axios.post(`/login`, { email, password });
      // store user's token in local storage
      localStorage.setItem(localStorageKeys.authToken, data.access_token);
      if (data.userInfo) {
        localStorage.setItem(localStorageKeys.googlePickerData, data.userInfo);
        console.log(data.userInfo);
      }

      const user = {
        id: data.id,
        email: data.email,
        phone: data.phone_number,
        userType: data.user_type,
        accessToken: data.access_token,
      };
      localStorage.setItem(localStorageKeys.userObj, user);
      return user;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
