import { createContext, useCallback, useEffect, useReducer } from "react";
import { localStorageKeys } from "../config/config";
import axios from "../utils/axios";
import { setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem(localStorageKeys.authToken);
        const userStr = localStorage.getItem(localStorageKeys.userObj);
        const user = JSON.parse(userStr);

        if (accessToken && user) {
          setSession(accessToken, user);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    try {
      const response = await axios.post("/login", {
        email,
        password,
      });

      if (!response.data || response.data.status === false) {
        throw new Error(response.data.message);
      }

      const data = response.data.data;
      const accessToken = data.access_token;
      const googlePickerData = data.userInfo ?? null;
      const user = {
        id: data.id,
        email: data.email,
        phone: data.phone_number,
        userType: data.user_type,
      };

      setSession(accessToken, user, googlePickerData);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
        },
      });
    } catch (e) {
      throw new Error(e.message || "Sign In failed");
    }
  };

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get("/user_details");

      if (!response.data || response.data.status === false) {
        throw new Error(response.data.message);
      }

      const data = response.data.data;
      if (data) {
        const user = {
          id: data.id,
          email: data.email,
          phone: data.phone_number,
          userType: data.user_type,
        };
        localStorage.setItem(localStorageKeys.userObj, JSON.stringify(user));

        dispatch({
          type: SIGN_IN,
          payload: {
            user,
          },
        });
      }
    } catch (e) {
      throw new Error(e.message || "Sign In failed");
    }
  }, []);

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        resetPassword,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
