import { createSlice } from "@reduxjs/toolkit";
import { signIn } from "../actions/authActions";
import { localStorageKeys } from "../../config/config";

const initialState = {
  loading: false,
  user: {}, // for user object
  accessToken: localStorage.getItem(localStorageKeys.authToken) ?? null, // for storing the api token
  error: null,
  success: false, // for monitoring the registration process.
  navLoader: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.navLoader = action.payload;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.accessToken = payload.accessToken;
      state.user = payload;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
export const { setLoader } = authSlice.actions;
