import React from "react";
import Backdrop from "@mui/material/Backdrop";
import HashLoader from "react-spinners/HashLoader";

const HashLoaderBackdrop = () => {
  return (
    <Backdrop
      open={true}
      sx={{ zIndex: 9999, backgroundColor: "rgba(0,0,0,0.1)" }}
    >
      <HashLoader size={80} color={"#264D91"} />
    </Backdrop>
  );
};

export default HashLoaderBackdrop;
