import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTypes = createAsyncThunk(
  "hintTypes/getTypes",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axios.post(`/api/hint_types`, {
        page: page,
        pageSize: pageSize,
        type: "FILTER",
        filters: [
          {
            column_name: "is_active",
            value: 1,
            type: "exact",
          },
        ],
      });
      if (data.status) {
        return data.data;
      }
      return rejectWithValue(data.message);
    } catch (e) {
      console.log("error", e);
      return rejectWithValue(e.message);
    }
  }
);
