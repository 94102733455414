import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chat: null,
  chatLoader: false,
};

export const ChatSlice = createSlice({
  name: "ChatSlice",
  initialState,
  reducers: {
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    setChatLoader: (state, action) => {
      state.chatLoader = action.payload;
    },
  },
});

export const { setChat, setChatLoader } = ChatSlice.actions;

export default ChatSlice.reducer;
