import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
const pagesSection = [
  {
    href: "/organization",
    icon: CorporateFareOutlinedIcon,
    title: "Organization",
  },

  {
    href: "/plans",
    icon: PaymentIcon,
    title: "Plans",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
