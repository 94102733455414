import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { setSnackBarAlert } from "../../redux/slice/AlertsSlice";
import { get } from "lodash";

const NotificationAlerts = () => {
  const dispatch = useDispatch();
  const snackBarAlert = useSelector((state) => state.Alerts.snackBarAlert);

  const autoHideDuration = get(snackBarAlert, "autoHideDuration", 4000);
  const anchorOrigin = get(snackBarAlert, "anchorOrigin", {
    vertical: "bottom",
    horizontal: "left",
  });
  const severity = get(snackBarAlert, "severity", "success");
  const elevation = get(snackBarAlert, "elevation", 6);
  const variant = get(snackBarAlert, "variant", "filled");
  const message = get(snackBarAlert, "message", "");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackBarAlert(null));
  };

  return snackBarAlert ? (
    <Snackbar
      open={true}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <MuiAlert
        onClose={handleClose}
        severity={severity}
        elevation={elevation}
        variant={variant}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  ) : null;
};

export default memo(NotificationAlerts);
