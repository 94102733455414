import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatHistory: null,
};

export const ChatHistorySlice = createSlice({
  name: "ChatHistorySlice",
  initialState,
  reducers: {
    setUserChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
  },
});

export const { setUserChatHistory } = ChatHistorySlice.actions;

export default ChatHistorySlice.reducer;
