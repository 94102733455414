import { createSlice } from "@reduxjs/toolkit";
import { getTypes } from "../actions/hintTypesActions";

const initialState = {
  loading: false,
  types: [], // for customer requests type dropdown
  error: "",
};

const hintTypesSlice = createSlice({
  name: "hintTypes",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getTypes.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTypes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.types = payload;
    },
    [getTypes.rejected]: (state, { payload }) => {
      state.types = [];
      state.loading = false;
      state.error = payload;
    },
  },
});

export default hintTypesSlice.reducer;
