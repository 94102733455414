import { Settings } from "@mui/icons-material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
const pagesSection = [
  {
    href: "/users",
    icon: PeopleAltOutlinedIcon,
    title: "Users",
  },
  {
    href: "/documents",
    icon: TextSnippetOutlinedIcon,
    title: "Documents",
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
